import React, { FC, useState } from 'react';
import { Dialog, IconButton, Typography, Container, Box } from '@mui/material';
import { Close as CloseIcon, CheckCircle } from '@mui/icons-material';
import { useMobileAppSuggestion } from 'hooks';
import {
  DownloadOnAppStoreIcon,
  GetItOnGooglePlayIcon,
} from 'components/icons';
import { useMediaQuery } from 'react-responsive';

const MobileAppBannerDesktop: FC = () => {
  const { shouldShow, links, handleSuggestion } = useMobileAppSuggestion();
  const [modalOpen, setModalOpen] = useState(true);
  const [followed, setFollowed] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (isMobile) {
    return null;
  }

  if (!links.ios && !links.android) {
    return null;
  }

  const IOSIcon = `${process.env.PUBLIC_URL}/apple-touch-icon.png`;

  const closeModal = () => {
    handleSuggestion(followed ? 'follow' : 'close');
    setModalOpen(false);
  };

  const followLink = () => setFollowed(true);

  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          padding: '32px',
          overflowY: 'inherit',
          backgroundColor: 'secondary.main',
        },
      }}
      open={shouldShow && modalOpen}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={closeModal}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>
      <Container>
        <Box
          sx={{
            display: 'flex',
            padding: '30px 0',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '92px',
              height: '92px',
              background: '#ffffff',
              borderRadius: '20px',
              padding: '10px',
              marginBottom: '20px',
            }}
          >
            {IOSIcon ? (
              <Box
                sx={{
                  objectFit: 'cover',
                  width: '100%',
                  minHeight: '100%',
                }}
                component="img"
                src={IOSIcon}
                alt="icon"
              />
            ) : (
              <CheckCircle sx={{ color: 'primary.main', fontSize: '48px' }} />
            )}
          </Box>
          <Typography
            sx={{
              fontSize: '17px',
              lineHeight: '20px',
              color: 'primary.contrastText',
              fontWeight: '400',
              marginBottom: '30px',
            }}
          >
            В приложении удобнее
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '16px',
              color: 'unset',
            }}
          >
            {links.ios && (
              <Box
                href={links.ios}
                target="_blank"
                rel="noopener noreferrer"
                component="a"
                color="inherit"
                onClick={followLink}
              >
                <DownloadOnAppStoreIcon
                  sx={{ fontSize: '48px', width: 'unset', height: '1em' }}
                />
              </Box>
            )}
            {links.android && (
              <Box
                href={links.android}
                target="_blank"
                rel="noopener noreferrer"
                component="a"
                color="inherit"
                onClick={followLink}
              >
                <GetItOnGooglePlayIcon
                  sx={{ fontSize: '48px', width: 'unset', height: '1em' }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default MobileAppBannerDesktop;
