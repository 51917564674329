import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GetItOnGooglePlayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="180"
      height="54"
      viewBox="0 0 180 54"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M173.3 54H6.7A6.7 6.7 0 0 1 0 47.2V6.8C0 3 3 0 6.7 0h166.6a6.7 6.7 0 0 1 6.7 6.8v40.5c0 3.7-3 6.7-6.7 6.7Z"
        fill="#000"
      />
      <path
        d="M27.6 26.2 13.4 41.5a3.8 3.8 0 0 0 5.7 2.3l16-9.3-7.5-8.3Z"
        fill="#EA4335"
      />
      <path
        d="m42 23.6-7-4-7.7 7 7.8 7.9 6.9-4a3.9 3.9 0 0 0 2-3.5 4 4 0 0 0-2-3.4Z"
        fill="#FBBC04"
      />
      <path
        d="M13.4 12.5a3.7 3.7 0 0 0-.1 1v27l.1 1 14.7-14.9-14.7-14Z"
        fill="#4285F4"
      />
      <path
        d="m27.7 27 7.4-7.4-16-9.4a3.9 3.9 0 0 0-5.7 2.3L27.7 27Z"
        fill="#34A853"
      />
      <path
        d="M142.6 40h2.5V23.3h-2.5V40ZM165 29.3l-2.9 7.3-3-7.3h-2.7l4.5 10.1-2.6 5.7h2.6l6.9-15.8H165Zm-14.1 8.8c-.8 0-2-.4-2-1.4 0-1.3 1.4-1.8 2.7-1.8 1 0 1.6.2 2.2.6a3 3 0 0 1-3 2.6Zm.3-9.1c-1.8 0-3.7.8-4.4 2.5l2.2 1c.4-1 1.3-1.3 2.2-1.3 1.3 0 2.6.8 2.7 2.2v.1a5.5 5.5 0 0 0-2.6-.6c-2.4 0-4.8 1.3-4.8 3.8 0 2.2 1.9 3.6 4 3.6a3.5 3.5 0 0 0 3.3-1.6V40h2.5v-6.4c0-3-2.2-4.6-5.1-4.6Zm-15.4 2.4h-3.5v-5.8h3.5a2.9 2.9 0 0 1 3 3 2.9 2.9 0 0 1-3 2.8Zm0-8h-6V40h2.5v-6.3h3.5c2.7 0 5.4-2 5.4-5.2s-2.7-5.2-5.4-5.2ZM103.3 38c-1.8 0-3.2-1.4-3.2-3.4s1.4-3.5 3.1-3.5c1.7 0 3 1.5 3 3.5s-1.3 3.4-3 3.4Zm2.8-7.8h-.1a4 4 0 0 0-3-1.3 5.6 5.6 0 0 0-5.4 5.7c0 3.2 2.6 5.6 5.4 5.6a4 4 0 0 0 3-1.2v.8c0 2.1-1 3.3-3 3.3a3.1 3.1 0 0 1-2.8-2L98 42a5.4 5.4 0 0 0 5 3.3c3 0 5.5-1.7 5.5-5.9v-10H106v.9Zm4 9.7h2.6V23.3h-2.5V40Zm6.3-5.5a3.1 3.1 0 0 1 3-3.3c.9 0 1.7.5 2 1.2l-5 2.1Zm7.7-1.9c-.5-1.3-2-3.6-4.9-3.6-2.9 0-5.3 2.3-5.3 5.7 0 3.1 2.4 5.6 5.6 5.6 2.6 0 4.1-1.5 4.7-2.5l-1.9-1.3a3.2 3.2 0 0 1-2.8 1.6 2.9 2.9 0 0 1-2.7-1.7l7.5-3.1-.2-.7Zm-60.4-1.9V33h5.7a5 5 0 0 1-1.3 3 5.9 5.9 0 0 1-4.4 1.8 6.3 6.3 0 0 1-6.3-6.4A6.3 6.3 0 0 1 68 27l1.7-1.7a8.4 8.4 0 0 0-6-2.5 9 9 0 0 0-9 8.8 9 9 0 0 0 9 8.8 8 8 0 0 0 6.1-2.4c1.6-1.6 2-3.8 2-5.6a7.8 7.8 0 0 0 0-1.5h-8.1ZM78.4 38c-1.7 0-3.2-1.4-3.2-3.4s1.5-3.5 3.2-3.5c1.8 0 3.2 1.4 3.2 3.5 0 2-1.4 3.4-3.2 3.4Zm0-9a5.6 5.6 0 0 0-5.7 5.6 5.6 5.6 0 0 0 5.7 5.6 5.6 5.6 0 0 0 5.7-5.6 5.6 5.6 0 0 0-5.7-5.7ZM91 38c-1.8 0-3.2-1.4-3.2-3.4s1.4-3.5 3.2-3.5c1.7 0 3.2 1.4 3.2 3.5 0 2-1.5 3.4-3.2 3.4Zm0-9a5.6 5.6 0 0 0-5.7 5.6 5.6 5.6 0 0 0 5.7 5.6 5.6 5.6 0 0 0 5.7-5.6 5.6 5.6 0 0 0-5.7-5.7ZM58 13.2a5.3 5.3 0 0 1-.8 3h4v-6H58v3ZM56.1 19h-1v-2.6h.9c.7-.6 1-1.7 1-3.1v-4h5.3v7h1V19h-1v-1.7h-6.1V19Z"
        fill="#fff"
      />
      <path
        d="M58 13.3h-.1c0 1.3-.3 2.2-.8 3l-.1.2h4.4v-6.3H58v3h.2v-2.8h3v5.8h-3.9v.1l.1.1c.6-.8.8-1.8.8-3v-.1H58ZM56.1 19h-.8v-2.4h.7c.8-.7 1.1-1.7 1.1-3.1v-4h5v7h1V19h-.8v-1.7H56V19h.3v-1.5H62v1.7h1.2v-3h-.9v-7h-5.6v4.2c0 1.4-.3 2.3-1 2.8l.2.2v-.2h-1v3h1.3V19h-.1Zm10-3.4a3 3 0 0 0 2.2 1 3 3 0 0 0 2.2-1 3 3 0 0 0 .9-2.3c0-.9-.3-1.7-1-2.3-.5-.6-1.3-.9-2.1-.9a3 3 0 0 0-2.2 1c-.6.5-.9 1.3-.9 2.2 0 1 .3 1.7 1 2.3Zm5.1.7a4 4 0 0 1-3 1.2 4 4 0 0 1-4-4.2 4 4 0 0 1 4-4.1 4.1 4.1 0 0 1 4.2 4.1 4.1 4.1 0 0 1-1.2 3Z"
        fill="#fff"
      />
      <path
        d="M66.1 15.6v.1c.5.7 1.3 1 2.2 1a3 3 0 0 0 2.3-1c.6-.6.9-1.4.9-2.4s-.3-1.7-1-2.4a3 3 0 0 0-2.2-.9 3 3 0 0 0-2.3 1 3.3 3.3 0 0 0-.9 2.3 3 3 0 0 0 1 2.4l.1-.2a3 3 0 0 1-.8-2.2 3 3 0 0 1 .8-2.2 2.8 2.8 0 0 1 2.1-.8c.8 0 1.5.3 2 .8a3 3 0 0 1 1 2.2 3 3 0 0 1-1 2.2 2.8 2.8 0 0 1-2 .9 2.8 2.8 0 0 1-2-.9l-.2.1Zm5.1.7v-.1a3.8 3.8 0 0 1-3 1.2 4 4 0 0 1-3.9-4 4 4 0 0 1 4-4.1c1.1 0 2 .4 2.8 1.2a4 4 0 0 1 1.2 2.8 4 4 0 0 1-1.2 3h.1l.1.1a4.2 4.2 0 0 0 1.2-3A4.2 4.2 0 0 0 68.3 9a4.2 4.2 0 0 0-4.3 4.3 4.2 4.2 0 0 0 4.3 4.3 4 4 0 0 0 3-1.2v-.1Zm6.4 1.2a4 4 0 0 1-3-1.2 4 4 0 0 1-1.2-3 4 4 0 0 1 1.2-3 4 4 0 0 1 3-1.1c1.2 0 2.2.4 3 1.3l-.8.7a2.7 2.7 0 0 0-2.2-1 3 3 0 0 0-3.1 3.1 3 3 0 0 0 3 3.2c1 0 1.8-.4 2.5-1.1l.7.7a4.2 4.2 0 0 1-3.1 1.4Z"
        fill="#fff"
      />
      <path
        d="M77.6 17.5v-.1a3.9 3.9 0 0 1-2.9-1.2 3.9 3.9 0 0 1-1.2-2.9 3.9 3.9 0 0 1 1.2-2.9 3.9 3.9 0 0 1 2.9-1.1c1.1 0 2 .4 2.8 1.3l.1-.1v-.1l-.8.7h.2a2.8 2.8 0 0 0-2.3-1.1 3.2 3.2 0 0 0-3.3 3.3 3.2 3.2 0 0 0 3.3 3.4c1 0 1.8-.4 2.5-1.2v-.1h-.2l.8.8V16a4 4 0 0 1-3.1 1.4v.3a4.3 4.3 0 0 0 3.3-1.5V16l-.9-1v.2a3 3 0 0 1-2.4 1.1 2.9 2.9 0 0 1-2.1-.9 3 3 0 0 1-.9-2.2c0-.9.3-1.6.9-2.2a2.9 2.9 0 0 1 2-.8c1 0 1.7.3 2.2 1h.1l.8-.8h.1v-.1A3.8 3.8 0 0 0 77.5 9c-1.2 0-2.2.4-3 1.3a4.2 4.2 0 0 0-1.3 3c0 1.2.4 2.3 1.2 3.1a4.2 4.2 0 0 0 3 1.2Zm7.3-.2h-1v-7h-2.3v-1h5.5v1H85v7Z"
        fill="#fff"
      />
      <path
        d="M84.9 17.3v-.1H84v-7h-2.2v-.7H87v.7h-2.2v7.1h.2v-6.9h2.3V9.3h-5.8v1.3h2.2v7H85v-.2Zm9.3-8-3 6.8c-.5 1-1 1.4-1.9 1.4-.2 0-.5 0-.8-.2l.3-1c.2.2.3.2.5.2l.6-.1.3-.5.3-.7-2.7-5.9H89l2 4.6 2-4.6h1.2Z"
        fill="#fff"
      />
      <path
        d="M94.2 9.3h-.1L91 16c-.5 1-1 1.4-1.8 1.4a2 2 0 0 1-.7-.2v.1l.3-.9h-.1v.1l.5.2c.3 0 .5 0 .6-.2l.5-.6.3-.6L88 9.4h1l2 4.6h.1l2-4.6h1.1v-.3h-1.3l-2 4.7h.2v-.1H91v.1h.1l-2-4.7h-1.5l2.8 6-.3.6a1 1 0 0 1-.3.5.8.8 0 0 1-.5.1 1 1 0 0 1-.5-.1l-.1-.1-.3 1.1v.1l.9.3c.9 0 1.6-.6 2-1.6l3.1-6.9h-.2v.1Zm1.2 8v-8h5.7v8h-1v-7h-3.7v7h-1Z"
        fill="#fff"
      />
      <path
        d="M95.4 17.3V9.5h5.6v7.7h-.8v-7h-4v7h-.9v.3h1.2v-7h3.5v7h1.3V9.2h-6v8.3-.2Zm7.6 0v-8h1v3.5h4V9.3h1v8h-1v-3.6h-4v3.6h-1Z"
        fill="#fff"
      />
      <path
        d="M103 17.3h.1V9.5h.8v3.4h4.2V9.5h.8v7.7h-.8v-3.6H104v3.6h-1v.3h1.2v-3.6h3.8v3.6h1.3V9.2H108v3.4h-4V9.2h-1.3v8.3h.2v-.2Zm9.3-1.7a3 3 0 0 0 2.2 1 3 3 0 0 0 2.2-1c.6-.6.8-1.3.8-2.3 0-.9-.3-1.7-.9-2.3-.5-.6-1.3-.9-2.1-.9a3 3 0 0 0-2.2 1c-.6.5-.9 1.3-.9 2.2 0 1 .3 1.7.9 2.3Zm5.1.7a4 4 0 0 1-3 1.2 4.1 4.1 0 0 1-4-4.2 4.1 4.1 0 0 1 4-4.1 4.1 4.1 0 0 1 4.2 4.1 4.1 4.1 0 0 1-1.2 3Z"
        fill="#fff"
      />
      <path
        d="m112.3 15.6-.1.1c.6.7 1.4 1 2.3 1a3 3 0 0 0 2.3-1c.6-.6.9-1.4.9-2.4s-.3-1.7-1-2.4a3 3 0 0 0-2.2-.9 3 3 0 0 0-2.3 1 3.3 3.3 0 0 0-1 2.3c0 1 .4 1.8 1 2.4h.1l.1-.2a3 3 0 0 1-.8-2.2 3 3 0 0 1 .8-2.2 2.8 2.8 0 0 1 2-.8c.9 0 1.6.3 2.2.8a3 3 0 0 1 .8 2.2 3 3 0 0 1-.8 2.2 2.8 2.8 0 0 1-2.1.9 2.8 2.8 0 0 1-2.1-.9l-.1.1Zm5.1.7v-.1a3.8 3.8 0 0 1-3 1.2 4 4 0 0 1-3.9-4 4 4 0 0 1 4-4.1c1.1 0 2 .4 2.8 1.2a4 4 0 0 1 1.2 2.8 4 4 0 0 1-1.2 3h.1l.1.1a4.2 4.2 0 0 0 1.2-3 4.2 4.2 0 0 0-4.2-4.4 4.2 4.2 0 0 0-4.3 4.3 4.2 4.2 0 0 0 4.3 4.3 4 4 0 0 0 3-1.2v-.1Zm6.4 0h2c.5 0 .8 0 1-.4.3-.2.4-.5.4-.9 0-.3-.1-.6-.4-.9a1.3 1.3 0 0 0-1-.4h-2v2.7Zm0-3.5h1.9c.4 0 .7-.2 1-.4l.3-.9c0-.3-.1-.5-.4-.8a1.1 1.1 0 0 0-.9-.4h-1.9v2.5Zm-1 4.5v-8h2.9c.6 0 1.1.2 1.6.7a2 2 0 0 1 .4 2.5 2 2 0 0 1-.8.7l1 .7c.2.3.4.7.4 1.1a2 2 0 0 1-.8 1.7c-.4.4-1 .6-1.7.6h-3Z"
        fill="#fff"
      />
      <path
        d="M123.8 16.4h2c.5 0 .9 0 1.1-.4.3-.3.4-.6.4-1s0-.7-.4-1c-.2-.3-.6-.4-1-.4h-2.2v2.9h.1v-.2h.1v-2.5h2c.3 0 .6.2.8.4.3.3.4.5.4.8 0 .3-.1.6-.3.8a1 1 0 0 1-1 .4h-2v.1Zm0-3.6v.1h1.9a1.4 1.4 0 0 0 1.4-1.4c0-.3-.1-.6-.4-.9-.2-.3-.6-.4-1-.4h-2v2.7h.1v-.1h.1v-2.3h1.8a1 1 0 0 1 .8.3c.2.2.3.5.3.7s0 .6-.3.8a1 1 0 0 1-.8.3h-1.9v.2Zm-1 4.5h.1V9.5h2.8c.6 0 1 .2 1.5.6.5.4.7.8.7 1.4 0 .4-.1.7-.3 1a1.7 1.7 0 0 1-.7.6h-.1v.2l1 .7a2 2 0 0 1-.3 2.6c-.5.4-1 .6-1.7.6h-3v.3h3c.7 0 1.3-.3 1.8-.7a2.3 2.3 0 0 0 .4-3c-.3-.3-.6-.6-1-.7v.2l.8-.7c.2-.3.3-.7.3-1 0-.8-.2-1.3-.7-1.8a2.5 2.5 0 0 0-1.7-.6h-3v8.3-.2Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default GetItOnGooglePlayIcon;
