import React, { FC } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Link,
} from '@mui/material';
import { Close as CloseIcon, CheckCircle } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { useMobileAppSuggestion } from 'hooks';

const MobileAppBanner: FC = () => {
  const isBanner = useMediaQuery({ maxWidth: 767 });
  const IOSIcon = `${process.env.PUBLIC_URL}/apple-touch-icon.png`;
  const { shouldShow, links, handleSuggestion } = useMobileAppSuggestion();

  if (!(links.android || links.ios)) {
    return null;
  }

  return (
    <>
      {isBanner && shouldShow && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'min(100dvw, 500px)',
            zIndex: '1500',
            backgroundColor: 'secondary.main',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '10px', right: '20px' }}
            onClick={() => handleSuggestion('close')}
            size="small"
            aria-label="close-btn"
          >
            <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
          </IconButton>
          <Container>
            <Box
              sx={{
                display: 'flex',
                padding: '30px 0 65px 0',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: '68px',
                  height: '68px',
                  background: '#ffffff',
                  borderRadius: '20px',
                  padding: '10px',
                  marginBottom: '20px',
                }}
              >
                {IOSIcon ? (
                  <Box
                    sx={{
                      objectFit: 'cover',
                      width: '100%',
                      minHeight: '100%',
                    }}
                    component="img"
                    src={IOSIcon}
                    alt="icon"
                  />
                ) : (
                  <CheckCircle
                    sx={{ color: 'primary.main', fontSize: '48px' }}
                  />
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: '17px',
                  lineHeight: '20px',
                  color: 'primary.contrastText',
                  fontWeight: '400',
                  marginBottom: '20px',
                }}
              >
                В приложении удобнее
              </Typography>
              <Button
                sx={{
                  minWidth: '188px',
                  height: '45px',
                  fontSize: '18px',
                  fontWeight: '300',
                  lineHeight: '21px',
                  borderRadius: '6px',
                  color: 'primary.contrastText',
                  boxShadow: 'none',
                  '&:focus': {
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
                component={Link}
                onClick={() => handleSuggestion('follow')}
                href={links.android || links.ios}
                target="_blank"
                rel="noopener noreferrer"
              >
                Открыть
              </Button>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default MobileAppBanner;
