import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';

const useMobileAppSuggestion = () => {
  const [cookie, setCookie] = useCookies(['app_banner']);
  const { IOS_APP_PAGE_LINK, ANDROID_APP_PAGE_LINK } = useAppSelector(
    selectAppSettingsData
  );

  const handleSuggestion = useCallback(
    (action: 'follow' | 'close') => {
      if (action === 'follow') {
        setCookie('app_banner', true, { maxAge: 2592000000 });
      } else if (action === 'close') {
        setCookie('app_banner', true, { maxAge: 604800000 });
      }
    },
    [setCookie]
  );

  const getLink = () => {
    const isAndroid = navigator.userAgent.match(/Android/i);

    const isIOS =
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i);

    if (!ANDROID_APP_PAGE_LINK && !IOS_APP_PAGE_LINK) {
      return {};
    }

    if (!isAndroid && !isIOS) {
      return {
        ...(ANDROID_APP_PAGE_LINK && { android: ANDROID_APP_PAGE_LINK }),
        ...(IOS_APP_PAGE_LINK && { ios: IOS_APP_PAGE_LINK }),
      };
    }

    if (isAndroid && ANDROID_APP_PAGE_LINK) {
      return { android: ANDROID_APP_PAGE_LINK };
    }

    if (isIOS && IOS_APP_PAGE_LINK) {
      return { ios: IOS_APP_PAGE_LINK };
    }

    return {};
  };

  return {
    shouldShow: !cookie.app_banner,
    links: getLink(),
    handleSuggestion,
  };
};

export default useMobileAppSuggestion;
